var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.name"),
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$i18n.t("common.Pleaseenteraname") },
                model: {
                  value: _vm.formListQuery.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "title", $$v)
                  },
                  expression: "formListQuery.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.ApplicableUserType"),
                prop: "userType",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: _vm.isEdit },
                  model: {
                    value: _vm.formListQuery.userType,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "userType", $$v)
                    },
                    expression: "formListQuery.userType"
                  }
                },
                _vm._l(_vm.dictionary.userType, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.code, attrs: { label: item.code } },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" + _vm._s(item.name) + "\n\t\t\t\t\t"
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Countryofuse"),
                prop: "countryCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: _vm.$i18n.t("common.Pleaseselectacountry"),
                    disabled: _vm.isEdit
                  },
                  model: {
                    value: _vm.formListQuery.countryCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "countryCode", $$v)
                    },
                    expression: "formListQuery.countryCode"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Discounttype"),
                prop: "type",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.isEdit },
                  model: {
                    value: _vm.formListQuery.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "type", $$v)
                    },
                    expression: "formListQuery.type"
                  }
                },
                _vm._l(_vm.dictionary.jump, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.formListQuery.type == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.discount"),
                    prop: "discount",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("span", [_vm._v(" - ")]),
                  _c("el-input", {
                    style: { width: _vm.inputWidth },
                    attrs: {
                      placeholder: _vm.$i18n.t("common.Pleaseentercontent")
                    },
                    model: {
                      value: _vm.formListQuery.discount,
                      callback: function($$v) {
                        _vm.$set(_vm.formListQuery, "discount", $$v)
                      },
                      expression: "formListQuery.discount"
                    }
                  }),
                  _vm._v("% off\n\t\t\t")
                ],
                1
              )
            : _vm.formListQuery.type == 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Fullreductionamount"),
                    prop: "discount1",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    style: { width: _vm.inputWidth },
                    attrs: {
                      oninput:
                        "value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\\.[0-9]{2})[0-9]*/,'$1'))",
                      placeholder: _vm.$i18n.t("common.Pleaseentercontent")
                    },
                    model: {
                      value: _vm.formListQuery.discount1,
                      callback: function($$v) {
                        _vm.$set(_vm.formListQuery, "discount1", $$v)
                      },
                      expression: "formListQuery.discount1"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Istheexchangecodefixed"),
                prop: "isFixedCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.isEdit },
                  model: {
                    value: _vm.formListQuery.isFixedCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "isFixedCode", $$v)
                    },
                    expression: "formListQuery.isFixedCode"
                  }
                },
                _vm._l(_vm.dictionary.fixedCode, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.formListQuery.isFixedCode == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Enterredemptioncode"),
                    prop: "code",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    style: { width: _vm.inputWidth },
                    attrs: {
                      disabled: _vm.isEdit,
                      placeholder: _vm.$i18n.t("common.Enterredemptioncode")
                    },
                    model: {
                      value: _vm.formListQuery.code,
                      callback: function($$v) {
                        _vm.$set(_vm.formListQuery, "code", $$v)
                      },
                      expression: "formListQuery.code"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Numberofredemptioncodes"),
                prop: "quantity",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                style: { width: _vm.inputWidth },
                attrs: {
                  oninput: "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                  placeholder: _vm.$i18n.t(
                    "common.Pleaseenterthenumberofredemptioncodes"
                  )
                },
                on: { blur: _vm.quantityChange },
                model: {
                  value: _vm.formListQuery.quantity,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "quantity", $$v)
                  },
                  expression: "formListQuery.quantity"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Availableamount"),
                prop: "price",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                style: { width: _vm.inputWidth },
                attrs: {
                  placeholder: _vm.$i18n.t(
                    "common.Pleaseenteranavailableamountof0toindicateunlimited"
                  ),
                  oninput: "value=value.replace(/^\\.+|[^\\d.]/g,'')"
                },
                model: {
                  value: _vm.formListQuery.price,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "price", $$v)
                  },
                  expression: "formListQuery.price"
                }
              })
            ],
            1
          ),
          _vm.formListQuery.type == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Maximumdeductionamount"),
                    prop: "maxDiscountPrice",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("el-input", {
                    style: { width: _vm.inputWidth },
                    attrs: {
                      placeholder: _vm.$i18n.t(
                        "common.PleaseEnterTheMaximumDeductionAmount0MeansUnlimited"
                      ),
                      oninput: "value=value.replace(/^\\.+|[^\\d.]/g,'')"
                    },
                    model: {
                      value: _vm.formListQuery.maxDiscountPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.formListQuery, "maxDiscountPrice", $$v)
                      },
                      expression: "formListQuery.maxDiscountPrice"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Numberofusesbythesameuser"),
                prop: "times",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                style: { width: _vm.inputWidth },
                attrs: {
                  oninput: "value=value.replace(/^0|[^0-9]/g, '')",
                  placeholder: _vm.$i18n.t(
                    "common.Pleaseenterthenumberoftimesthesameuserhasusedit"
                  )
                },
                model: {
                  value: _vm.formListQuery.times,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "times", $$v)
                  },
                  expression: "formListQuery.times"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Effectivetime"),
                prop: "time",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "range-separator": _vm.$i18n.t("common.to"),
                  "start-placeholder": _vm.$i18n.t("common.starttime"),
                  "end-placeholder": _vm.$i18n.t("common.Endtime")
                },
                on: { change: _vm.changeTime, input: _vm.changeCheckList },
                model: {
                  value: _vm.formListQuery.time,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "time", $$v)
                  },
                  expression: "formListQuery.time"
                }
              })
            ],
            1
          ),
          _vm.formListQuery.isFixedCode == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.MakeCouponPublic"),
                    prop: "isOpen",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formListQuery.isOpen,
                        callback: function($$v) {
                          _vm.$set(_vm.formListQuery, "isOpen", $$v)
                        },
                        expression: "formListQuery.isOpen"
                      }
                    },
                    _vm._l(_vm.dictionary.isOpen, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.id, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Isitpossibletooverlaydistribution"),
                prop: "isOverlayDistribution",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formListQuery.isOverlayDistribution,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "isOverlayDistribution", $$v)
                    },
                    expression: "formListQuery.isOverlayDistribution"
                  }
                },
                _vm._l(_vm.dictionary.isOverlayDistribution, function(item) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Canpromotionsbestacked"),
                prop: "isOverlayPromotion",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formListQuery.isOverlayPromotion,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "isOverlayPromotion", $$v)
                    },
                    expression: "formListQuery.isOverlayPromotion"
                  }
                },
                _vm._l(_vm.dictionary.isOverlayPromotion, function(item) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.explain"),
                prop: "describe",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 4 },
                  placeholder: _vm.$i18n.t("common.Entercoupondescription")
                },
                model: {
                  value: _vm.formListQuery.describe,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "describe", $$v)
                  },
                  expression: "formListQuery.describe"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Enableornot"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                on: { change: _vm.changeStatus },
                model: {
                  value: _vm.formListQuery.status1,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "status1", $$v)
                  },
                  expression: "formListQuery.status1"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Applicableclassification"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  props: _vm.defaultProps,
                  data: _vm.dictionary.classifyTree,
                  "node-key": "goodsClassifyId",
                  "default-checked-keys": _vm.checkedKeys,
                  "show-checkbox": ""
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.Applicableproducts"),
                prop: "brand",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.changeGoods } },
                [_vm._v(_vm._s(_vm.$i18n.t("common.Addproduct")))]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "brand", "label-width": _vm.formLabelWidth } },
            [
              _c(
                "el-table",
                {
                  ref: "pageDataSelect",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "row-key": "goodsId",
                    data: _vm.pageDataSelectList,
                    border: ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsId",
                      label: _vm.$i18n.t("common.ProductID"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsCode",
                      label: _vm.$i18n.t("common.SKU_EAN_Encoding"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v("SKU:" + _vm._s(scope.row.sku))
                            ]),
                            _c("br"),
                            _c("span", [
                              _vm._v("EAN:" + _vm._s(scope.row.barCode))
                            ]),
                            _c("br"),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$i18n.t("common.coding")) +
                                  ":" +
                                  _vm._s(scope.row.goodsCode)
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "imageUrl",
                      label: _vm.$i18n.t("common.Productimage1"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.imageUrl
                              ? _c("el-image", {
                                  staticStyle: { height: "100px" },
                                  attrs: {
                                    src: _vm.ossHost + scope.row.imageUrl,
                                    fit: "fill"
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: _vm.$i18n.t("common.ProductName"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsSpecials",
                      label: _vm.$i18n.t("common.Countrieslisted"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.goodsSpecials, function(
                            s,
                            index
                          ) {
                            return _c("span", { key: index }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.$dict.getDictValue(
                                      "countrys",
                                      s.countryCode
                                    )
                                  )
                              ),
                              _c("br")
                            ])
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "endTime",
                      label: _vm.$i18n.t("common.Promotionprice1"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return scope.row.prices.length > 0
                              ? _vm._l(scope.row.prices, function(s, index) {
                                  return _c("span", { key: index }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.$dict.getDictValue(
                                            "countrys",
                                            s.countryCode
                                          )
                                        ) +
                                        ":"
                                    ),
                                    s.distributionPrice
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              s.distributionPrice.toFixed(2)
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("br")
                                  ])
                                })
                              : undefined
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brand",
                      label: _vm.$i18n.t("common.fixedprice"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.prices, function(s, index) {
                            return _c("span", { key: index }, [
                              _vm._v(" " + _vm._s(s.resultPrice.toFixed(2))),
                              _c("br")
                            ])
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelf",
                      label: _vm.$i18n.t("common.state"),
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.shelf == true
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#46a8a0" } },
                                  [_vm._v(_vm._s(_vm.$i18n.t("common.Listed")))]
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$i18n.t("common.fixedprice"))
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Selectproduct"),
            visible: _vm.dialogFormTableVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormTableVisible = $event
            },
            close: _vm.closeDialogGoods
          }
        },
        [
          _vm.dialogFormTableVisible
            ? _c(
                "div",
                [
                  _c("FormTableDialog", {
                    attrs: {
                      argFrom: _vm.argFrom,
                      dictionary: _vm.dictionary,
                      pageDataSelectList: _vm.pageDataSelectList,
                      placeholderFrom: _vm.placeholderFrom,
                      selectedMount: _vm.selectedMount
                    },
                    on: {
                      handleSearchList: _vm.handleSearchList2,
                      handleResetSearch: _vm.handleResetSearch,
                      handleSelected: _vm.handleSelected
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tableSlot",
                          fn: function() {
                            return [
                              _c(
                                "el-table",
                                {
                                  ref: "tableData",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "row-key": "goodsId",
                                    "default-expand-all": "",
                                    data: _vm.pageData2.list,
                                    border: "",
                                    "tree-props": {
                                      children: "packageQtyList",
                                      hasChildren: "hasChildren"
                                    },
                                    id: "goodsTable",
                                    "header-cell-style": {
                                      background: "#EAEEF9",
                                      color: "#32353a"
                                    }
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                    select: _vm.selectOfSelectable,
                                    "select-all": _vm.selectAllOfSelectable
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      width: "55",
                                      "reserve-selection": true,
                                      align: "center",
                                      "show-overflow-tooltip": "",
                                      selectable: function(row) {
                                        return row.checkboxStatus
                                      }
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "quantityType",
                                      label: "",
                                      width: "50px",
                                      align: "center"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsId",
                                      label: _vm.$i18n.t("common.ProductID"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsCode",
                                      label: _vm.$i18n.t(
                                        "common.SKU_EAN_Encoding"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  "SKU:" + _vm._s(scope.row.sku)
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "EAN:" +
                                                    _vm._s(scope.row.barCode)
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$i18n.t("common.coding")
                                                  ) +
                                                    ":" +
                                                    _vm._s(scope.row.goodsCode)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1138614848
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "imageUrl",
                                      label: _vm.$i18n.t(
                                        "common.Productimage1"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.imageUrl
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      height: "100px"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.ossHost +
                                                        scope.row.imageUrl,
                                                      fit: "fill"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      183490098
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packageQuantity",
                                      label: _vm.$i18n.t(
                                        "common.PackingQuantity"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "title",
                                      label: _vm.$i18n.t("common.ProductName"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsSpecials",
                                      label: _vm.$i18n.t(
                                        "common.Countrieslisted"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.goodsSpecials,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.$dict.getDictValue(
                                                            "countrys",
                                                            s.countryCode
                                                          )
                                                        )
                                                    ),
                                                    _c("br")
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1204783595
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$i18n.t(
                                        "common.Promotionprice_unitprice"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm.isShowGoodsPromotion(
                                                scope.row.promotionRuleCountrys,
                                                "NEW"
                                              )
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            "text-align":
                                                              "left",
                                                            display: "block"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.NewUserPromotion"
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        scope.row
                                                          .promotionRuleCountrys,
                                                        function(
                                                          promotion,
                                                          index
                                                        ) {
                                                          return promotion.userType ==
                                                            "NEW"
                                                            ? _c(
                                                                "span",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                " +
                                                                      _vm._s(
                                                                        _vm.$dict.getDictValue(
                                                                          "countrys",
                                                                          promotion.countryCode
                                                                        )
                                                                      ) +
                                                                      ":" +
                                                                      _vm._s(
                                                                        _vm.getPromotionGoodsPrice(
                                                                          promotion,
                                                                          scope
                                                                            .row
                                                                            .prices
                                                                        )
                                                                      )
                                                                  ),
                                                                  _c("br")
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm.isShowGoodsPromotion(
                                                scope.row.promotionRuleCountrys,
                                                "OLD"
                                              )
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            "text-align":
                                                              "left",
                                                            display: "block"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.RegularUserPromotion"
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        scope.row
                                                          .promotionRuleCountrys,
                                                        function(
                                                          promotion,
                                                          index
                                                        ) {
                                                          return promotion.userType ==
                                                            "OLD"
                                                            ? _c(
                                                                "span",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                " +
                                                                      _vm._s(
                                                                        _vm.$dict.getDictValue(
                                                                          "countrys",
                                                                          promotion.countryCode
                                                                        )
                                                                      ) +
                                                                      ":" +
                                                                      _vm._s(
                                                                        _vm.getPromotionGoodsPrice(
                                                                          promotion,
                                                                          scope
                                                                            .row
                                                                            .prices
                                                                        )
                                                                      )
                                                                  ),
                                                                  _c("br")
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              !scope.row
                                                .promotionRuleCountrys ||
                                              scope.row.promotionRuleCountrys
                                                .length == 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "common.ThereAreCurrentlyNoProductPromotions"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3883861760
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$i18n.t("common.fixedprice"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.prices,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(s.countryCode) +
                                                        ":" +
                                                        _vm._s(
                                                          s.resultPrice.toFixed(
                                                            2
                                                          )
                                                        )
                                                    ),
                                                    _c("br")
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      324010042
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "shelf",
                                      label: _vm.$i18n.t("common.state"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.shelf == true
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#46a8a0"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$i18n.t(
                                                            "common.Listed"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "common.Notlisted"
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2074187132
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c("el-pagination", {
                                    attrs: {
                                      "current-page": _vm.listQuery.page,
                                      "page-size": _vm.listQuery.size,
                                      layout:
                                        "total, prev, pager, next, jumper",
                                      total: _vm.pageData2.total
                                    },
                                    on: {
                                      "current-change": _vm.handleCurrentChange
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2811269186
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Redemptioncode"),
            visible: _vm.dialogFormTableVisible2,
            "close-on-click-modal": false,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormTableVisible2 = $event
            }
          }
        },
        [
          _vm.dialogFormTableVisible2
            ? _c(
                "div",
                [
                  _c("FormTableDialog", {
                    attrs: {
                      argFrom: _vm.argFrom2,
                      dictionary: _vm.dictionary,
                      placeholderFrom: _vm.placeholderFrom2,
                      closeFooter: true,
                      isShowObjectFrom: _vm.isShowObjectFrom
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tableSlot",
                          fn: function() {
                            return [
                              _c(
                                "el-table",
                                {
                                  ref: "pagedata",
                                  staticClass: "tableGoods",
                                  staticStyle: { width: "100%" },
                                  attrs: { data: _vm.tableData, border: "" },
                                  on: {
                                    "selection-change": _vm.selectionChange
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "id",
                                      label: _vm.$i18n.t("common.number"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "code",
                                      label: _vm.$i18n.t(
                                        "common.Redemptioncode"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "imgs",
                                      label: _vm.$i18n.t("common.Claimstatus"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "country",
                                      label: _vm.$i18n.t(
                                        "common.Collectiontime"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "brand",
                                      label: _vm.$i18n.t("common.Usagestatus"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "status",
                                      label: _vm.$i18n.t("common.Usagetime"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "status",
                                      label: _vm.$i18n.t("common.UseUserID"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "status",
                                      label: _vm.$i18n.t(
                                        "common.Useordernumber"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      24412387
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }