<template>
	<div>
		<el-form :model="formListQuery" :rules="rules" ref="variantFrom" v-loading="formLoading">
			<el-form-item :label='$i18n.t("common.name")' prop="title" :label-width="formLabelWidth">
				<el-input v-model="formListQuery.title" :placeholder='$i18n.t("common.Pleaseenteraname")'></el-input>
			</el-form-item>

			<el-form-item :label='$i18n.t("common.ApplicableUserType")'  prop="userType" :label-width="formLabelWidth">
				<el-checkbox-group v-model="formListQuery.userType" :disabled="isEdit">
					<el-checkbox v-for="(item, index) in dictionary.userType" :key="item.code" :label="item.code">
						{{ item.name }}
					</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Countryofuse")' prop="countryCode" :label-width="formLabelWidth">
				<el-select v-model="formListQuery.countryCode" :placeholder='$i18n.t("common.Pleaseselectacountry")' :disabled="isEdit">

					<el-option v-for="item in dictionary.country" :key="item.id" :label="item.name" :value="item.code">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Discounttype")'  prop="type" :label-width="formLabelWidth">
				<el-radio-group v-model="formListQuery.type" :disabled="isEdit">
					<el-radio v-for="(item, index) in dictionary.jump" :key="item.id"
						:label="item.value">{{ item.name }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="formListQuery.type == 1" :label='$i18n.t("common.discount")' prop="discount" :label-width="formLabelWidth">
				<span> - </span><el-input v-model="formListQuery.discount" :placeholder='$i18n.t("common.Pleaseentercontent")'
					:style="{ width: inputWidth }"></el-input>% off
			</el-form-item>
			<el-form-item v-else-if="formListQuery.type == 2" :label='$i18n.t("common.Fullreductionamount")' prop="discount1" :label-width="formLabelWidth">
				<el-input v-model="formListQuery.discount1"
					oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"
					:placeholder='$i18n.t("common.Pleaseentercontent")' :style="{ width: inputWidth }"></el-input>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Istheexchangecodefixed")' prop="isFixedCode" :label-width="formLabelWidth">
				<el-radio-group v-model="formListQuery.isFixedCode" :disabled="isEdit">
					<el-radio v-for="(item, index) in dictionary.fixedCode" :key="item.id"
						:label="item.value">{{ item.name }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="formListQuery.isFixedCode == 1" :label='$i18n.t("common.Enterredemptioncode")'  prop="code" :label-width="formLabelWidth">
				<el-input v-model="formListQuery.code" :disabled="isEdit" :placeholder='$i18n.t("common.Enterredemptioncode")'
					:style="{ width: inputWidth }"></el-input>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Numberofredemptioncodes")' prop="quantity" :label-width="formLabelWidth">
				<el-input v-model="formListQuery.quantity" @blur="quantityChange"
					oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :placeholder='$i18n.t("common.Pleaseenterthenumberofredemptioncodes")'
					:style="{ width: inputWidth }"></el-input>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Availableamount")' prop="price" :label-width="formLabelWidth">
				<el-input v-model="formListQuery.price" :placeholder='$i18n.t("common.Pleaseenteranavailableamountof0toindicateunlimited")'
					oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :style="{ width: inputWidth }"></el-input>
			</el-form-item>
			<el-form-item v-if="formListQuery.type == 1" :label='$i18n.t("common.Maximumdeductionamount")' prop="maxDiscountPrice" :label-width="formLabelWidth">
				<el-input v-model="formListQuery.maxDiscountPrice" :placeholder='$i18n.t("common.PleaseEnterTheMaximumDeductionAmount0MeansUnlimited")'
					oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :style="{ width: inputWidth }"></el-input>
			</el-form-item>
			<!-- <el-form-item label="是否可叠加" prop="isOverlayDiscount" :label-width="formLabelWidth">
				<el-radio-group v-model="formListQuery.isOverlayDiscount">
				    <el-radio v-for="(item) in dictionary.over" :key="item.id" :label="item.value">{{item.name}}</el-radio>
				</el-radio-group>
			</el-form-item> -->
			<el-form-item :label='$i18n.t("common.Numberofusesbythesameuser")' prop="times" :label-width="formLabelWidth">
				<el-input v-model="formListQuery.times" oninput="value=value.replace(/^0|[^0-9]/g, '')"
					:placeholder='$i18n.t("common.Pleaseenterthenumberoftimesthesameuserhasusedit")' :style="{ width: inputWidth }"></el-input>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Effectivetime")'  prop="time" :label-width="formLabelWidth">
				<el-date-picker v-model="formListQuery.time" clearable @change="changeTime" @input="changeCheckList"
					type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" :range-separator='$i18n.t("common.to")'
					:start-placeholder='$i18n.t("common.starttime")' :end-placeholder='$i18n.t("common.Endtime")'>
				</el-date-picker>
			</el-form-item>
            <el-form-item  v-if="formListQuery.isFixedCode == 1" :label='$i18n.t("common.MakeCouponPublic")' prop="isOpen" :label-width="formLabelWidth">
                <el-radio-group v-model="formListQuery.isOpen" >
                    <el-radio v-for="(item) in dictionary.isOpen" :key="item.id"
                              :label="item.value">{{ item.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
			<el-form-item :label='$i18n.t("common.Isitpossibletooverlaydistribution")' prop="isOverlayDistribution" :label-width="formLabelWidth">
				<el-radio-group v-model="formListQuery.isOverlayDistribution">
					<el-radio v-for="(item) in dictionary.isOverlayDistribution" :key="item.id"
						:label="item.value">{{ item.name }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Canpromotionsbestacked")' prop="isOverlayPromotion" :label-width="formLabelWidth">
				<el-radio-group v-model="formListQuery.isOverlayPromotion">
					<el-radio v-for="(item) in dictionary.isOverlayPromotion" :key="item.id"
						:label="item.value">{{ item.name }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.explain")' prop="describe" :label-width="formLabelWidth">
				<el-input type="textarea" :autosize="{ minRows: 4 }" :placeholder='$i18n.t("common.Entercoupondescription")'
					v-model="formListQuery.describe"></el-input>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Enableornot")'  :label-width="formLabelWidth">
				<el-switch v-model="formListQuery.status1" @change="changeStatus"></el-switch>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Applicableclassification")' :label-width="formLabelWidth">
				<el-tree ref="tree" :props="defaultProps" :data="dictionary.classifyTree" node-key="goodsClassifyId"
					:default-checked-keys="checkedKeys" show-checkbox>
				</el-tree>
			</el-form-item>
			<el-form-item :label='$i18n.t("common.Applicableproducts")' prop="brand" :label-width="formLabelWidth">
				<el-button type="success" @click="changeGoods">{{ $i18n.t("common.Addproduct") }}</el-button>
			</el-form-item>
			<el-form-item prop="brand" :label-width="formLabelWidth">

				<el-table ref="pageDataSelect" row-key="goodsId" :data="pageDataSelectList"  border style="width: 100%">
					<el-table-column prop="goodsId" :label='$i18n.t("common.ProductID")'  align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")' align="center" show-overflow-tooltip>

						<template slot-scope="scope">
							<span>SKU:{{ scope.row.sku }}</span><br />
							<span>EAN:{{ scope.row.barCode }}</span><br />
							<span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-image style="height: 100px" v-if="scope.row.imageUrl" :src="ossHost + scope.row.imageUrl"
								fit="fill"></el-image>
						</template>
					</el-table-column>
					<el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
								{{ $dict.getDictValue('countrys', s.countryCode) }}<br />
							</span>
						</template>
					</el-table-column>

<!--					<el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip></el-table-column>-->
					<el-table-column prop="endTime" :label='$i18n.t("common.Promotionprice1") '  align="center" show-overflow-tooltip>

						<template slot-scope="scope" v-if="scope.row.prices.length > 0">
							<span v-for="(s, index) in scope.row.prices" :key="index">
								{{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.distributionPrice">{{
									s.distributionPrice.toFixed(2) }}</span><br />
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="brand" :label='$i18n.t("common.fixedprice") '  align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-for="(s, index) in scope.row.prices" :key="index"> {{ s.resultPrice.toFixed(2) }}<br />
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="shelf" :label='$i18n.t("common.state")'  align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="scope.row.shelf == true" style="color: #46a8a0;">{{ $i18n.t("common.Listed") }}</span>
							<span v-else>{{ $i18n.t("common.fixedprice") }}</span>
						</template>
					</el-table-column>
				</el-table>
			</el-form-item>

		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog"> {{ $i18n.t("common.cancel") }}</el-button>
			<el-button style="width: 20%;" type="primary" @click="onSubmit('variantFrom')"> {{ $i18n.t("common.confirm") }}</el-button>
		</div>
		<el-dialog :title='$i18n.t("common.Selectproduct")'  :visible.sync="dialogFormTableVisible" @close="closeDialogGoods" append-to-body>
			<div v-if="dialogFormTableVisible">
				<FormTableDialog :argFrom='argFrom' :dictionary="dictionary" :pageDataSelectList="pageDataSelectList"
					:placeholderFrom="placeholderFrom" :selectedMount="selectedMount" @handleSearchList='handleSearchList2'
					@handleResetSearch='handleResetSearch' @handleSelected='handleSelected'>
					<template v-slot:tableSlot>
						<el-table @selection-change="handleSelectionChange" row-key="goodsId" ref="tableData" default-expand-all
							:data="pageData2.list" style="width: 100%" border
							:tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
							id="goodsTable"
							@select="selectOfSelectable"
							@select-all="selectAllOfSelectable"
							:header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">
							<el-table-column type="selection" width="55" :reserve-selection="true" align="center"

								show-overflow-tooltip :selectable="row => row.checkboxStatus"></el-table-column>
							<!-- 用于显示子表格张开按钮 -->
							<el-table-column prop="quantityType" label="" width="50px" align="center">
                            </el-table-column>
							<el-table-column prop="goodsId" :label='$i18n.t("common.ProductID")'  align="center"

								show-overflow-tooltip></el-table-column>
							<el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")'  align="center" show-overflow-tooltip>
								<template slot-scope="scope">
									<span>SKU:{{ scope.row.sku }}</span><br />
									<span>EAN:{{ scope.row.barCode }}</span><br />
									<span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
								<template slot-scope="scope">
									<el-image style="height: 100px" v-if="scope.row.imageUrl"
										:src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
								</template>
							</el-table-column>

                            <el-table-column prop="packageQuantity" :label='$i18n.t("common.PackingQuantity")' align="center" show-overflow-tooltip></el-table-column>
							<el-table-column prop="title" :label='$i18n.t("common.ProductName")' align="center"

								show-overflow-tooltip></el-table-column>
							<el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")'  align="center" show-overflow-tooltip>
								<template slot-scope="scope">
									<span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
										{{ $dict.getDictValue('countrys', s.countryCode) }}<br />
									</span>
								</template>
							</el-table-column>

<!--							<el-table-column prop="brandCode" label="品牌" align="center"
								show-overflow-tooltip></el-table-column>-->

<!--							<el-table-column prop="endTime" :label='$i18n.t("common.Promotionprice1") ' align="center" show-overflow-tooltip>

								<template slot-scope="scope" v-if="scope.row.prices.length > 0">
									<span v-for="(s, index) in scope.row.prices" :key="index">
										{{ $dict.getDictValue('countrys', s.countryCode) }}:<span
											v-if="s.distributionPrice">{{ s.distributionPrice.toFixed(2) }}</span><br />
									</span>
								</template>
							</el-table-column>-->
							<el-table-column prop="prices" :label='$i18n.t("common.Promotionprice_unitprice")' align="center" show-overflow-tooltip>
								<template slot-scope="scope">
                        <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                            <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.NewUserPromotion') }}<br/></span>
                            <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                            </span>
                        </span>
									<span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                            <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.RegularUserPromotion') }}<br/></span>
                            <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                            </span>
                        </span>
									<span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="prices"  :label='$i18n.t("common.fixedprice") '  align="center" show-overflow-tooltip>
								<template slot-scope="scope">
									<span v-for="(s, index) in scope.row.prices" :key="index"> {{ s.countryCode }}:{{
										s.resultPrice.toFixed(2) }}<br /> </span>
								</template>
							</el-table-column>
							<el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
								<template slot-scope="scope">
									<span v-if="scope.row.shelf == true" style="color: #46a8a0;">{{ $i18n.t("common.Listed") }}</span>
									<span v-else>{{ $i18n.t("common.Notlisted") }}</span>
								</template>
							</el-table-column>
						</el-table>
						<div class="block">
							<el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
								:page-size="listQuery.size" layout="total, prev, pager, next, jumper"
								:total="pageData2.total">
							</el-pagination>
						</div>
					</template>
				</FormTableDialog>
			</div>
		</el-dialog>
		<el-dialog :title='$i18n.t("common.Redemptioncode")' :visible.sync="dialogFormTableVisible2" :close-on-click-modal="false" append-to-body>
			<div v-if="dialogFormTableVisible2">
				<FormTableDialog :argFrom="argFrom2" :dictionary='dictionary' :placeholderFrom="placeholderFrom2"
					:closeFooter='true' :isShowObjectFrom="isShowObjectFrom">
					<template v-slot:tableSlot>
						<el-table ref="pagedata" :data="tableData" @selection-change="selectionChange" border
							class="tableGoods" style="width: 100%">
							<el-table-column prop="id" :label='$i18n.t("common.number")' align="center" show-overflow-tooltip> </el-table-column>
							<el-table-column prop="code" :label='$i18n.t("common.Redemptioncode")' align="center" show-overflow-tooltip></el-table-column>
							<el-table-column prop="imgs" :label='$i18n.t("common.Claimstatus")' align="center" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="country" :label='$i18n.t("common.Collectiontime")' align="center"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="brand" :label='$i18n.t("common.Usagestatus")' align="center"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="status" :label='$i18n.t("common.Usagetime")' align="center"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="status" :label='$i18n.t("common.UseUserID")'  align="center"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="status" :label='$i18n.t("common.Useordernumber")' align="center"
								show-overflow-tooltip></el-table-column>
						</el-table>
					</template>
				</FormTableDialog>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import {selectAllOfSelectable, selectOfSelectable, showSelectOfSelectable} from '@/utils/treeTable';
import { formatDate } from '@/utils/date';
import { enumeration, randomString } from '@/utils/common2';
import cache from '@/utils/cache';
import FilterContainer from '@/components/common/FilterContainer.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
export default {
	components: {
		FilterContainer,
		FormTableDialog
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false
		},
		formListQuery: {
			type: Object,
			default: () => {
				return {}
			}
		},
		dictionary: {
			type: Object,
			default: () => {
				return {}
			}
		},
		dialogId: {
			type: String,
			default: ''
		}
	},
	filters: {
		formatDate(time) {
			if (time == null || time === '') {
				return 'N/A';
			}
			let date = new Date(time);
			return formatDate(date, 'yyyy-MM-dd')
		},
		enumeration(val) {
			return enumeration(val)
		}
	},
	data() {
		return {
			defaultProps: {
				label: function (data, node) {
					return data.title
				},
				id: function (data, node) {
					return data.parameterGroupId
				},
			},
			formLoading:false,
			ossHost: process.env.VUE_APP_OSS_Head,
			selectionFlag: false,
			selectedMount: null,
			formListQueryQuantity: '',
			formLabelWidth: '150px',
			inputWidth: '215px',
			activeIndex: null,
			pageData2: {},
			listQuery: {
				"countryCode": "",
				"classifyId": '',
				"idFuzzy": "",
				"brandCode": "",
				"updateUserId": "",//操作人
				"inPromotion": "", //是否促销
				"isOnShelf": "",//是否上架
				"beginCreateTime": "",
				"endCreateTime": '',
				"beginUpdateTime": "",
				"endUpdateTime": '',
				"page": 1,
				"size": 10,
			},
			defaultListQuery: {
				"countryCode": "",
				"classifyId": '',
				"idFuzzy": "",
				"brandCode": "",
				"updateUserId": "",//操作人
				"inPromotion": "", //是否促销
				"isOnShelf": "",//是否上架
				"beginCreateTime": "",
				"endCreateTime": '',
				"beginUpdateTime": "",
				"endUpdateTime": '',
				"page": 1,
				"size": 10
			},
			//组件标签是否显示
			arg: {
				code: true,
				country: true,
				status: true
			},
			//组件标签是否显示
			argFrom: {
				code: true,
				promotion: true,
				state: true
			},
			argFrom2: {
				name: true,
				receiveState: true,
				state: true
			},
			placeholderFrom: {
				promotion:this.$i18n.t("common.Whethertopromoteornot"),
				state: this.$i18n.t("common.state")
			},
			placeholderFrom2: {
				name: this.$i18n.t("common.UseOrderNumberExchangeCodeUserID"),
				state: this.$i18n.t("common.Usagestatus")
			},
			isShowObjectFrom: {
				export: true
			},
			dialogFormTableVisible: false,
			dialogFormTableVisible2: false,
			//查询项
			searchData: {
				searchStr: '',
				saleCountry: [],
				platformCode: [],
				enabled: null,
				arrayDate: [],
				page: 1,
				limit: 10
			},
			//选中数量
			rules: {
				title: [{
					required: true,
					message: this.$i18n.t("common.Pleaseenteraname"),
					trigger: 'blur'
				},
				{
					min: 1,
					max: 50,
					message: this.$i18n.t("common.Between1and50charactersinlength"),
					trigger: 'blur'
				}],
				countryCode: [{
					required: true,
					message:this.$i18n.t("common.Pleaseselectacountry"),
					trigger: 'change'
				}],
				type: [{
					required: true,
					message: this.$i18n.t("common.Discounttype"),
					trigger: 'change'
				}],
				time: [
					{
						required: true,
						message: this.$i18n.t("common.Pleaseselectadate"),
						trigger: 'change'
					}
				],
				discount: [{
					required: true,
					message: this.$i18n.t("common.PleaseEnterTheDiscount"),
					trigger: 'blur'
				},
				{
					pattern: /(^(\d|[1-9]\d)(\.\d{1,1})?$)|(^100$)/,
					message: this.$i18n.t("common.OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace"),
					trigger: 'blur'
				}],
				discount1: [{
					required: true,
					message: this.$i18n.t("common.PleaseEnterTheFullReductionAmount"),
					trigger: 'blur'
				}],
				code: [{
					required: true,
					message: this.$i18n.t("common.PleaseEnterTheRedeemCode"),
					trigger: 'blur'
				},
				{
					pattern: /^[a-zA-Z0-9]{6,20}$/,
					message: this.$i18n.t("common.OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits"),
					trigger: 'blur'
				}],
				isFixedCode: [{
					required: true,
					message: this.$i18n.t("common.PleaseSelectAFixedExchangeCode"),
					trigger: 'change'
				}],
				quantity: [{
					required: true,
					message: this.$i18n.t("common.Pleaseenterthenumberofredemptioncodes"),
					trigger: 'blur'
				}],
				price: [{
					required: true,
					message: this.$i18n.t("common.Pleaseentertheavailableamount"),
					trigger: 'blur'
				}],
				maxDiscountPrice: [{
					required: true,
					message: this.$i18n.t("common.PleaseEnterTheMaximumDeductionAmount"),
					trigger: 'blur'
				}],
				times: [{
					required: true,
					message: this.$i18n.t("common.Pleaseenterthenumberoftimesthesameuserhasusedit"),
					trigger: 'blur'
				}],
				isOverlayDiscount: [{
					required: true,
					message: this.$i18n.t("common.PleaseSelectWhetherItCanbeStacked"),
					trigger: 'change'
				}],
				describe: [{
					required: true,
					message: this.$i18n.t("common.Pleaseenteradescription"),
					trigger: 'blur'
				}]
			},
			pageDataSelectList: [],
			checkedKeys: [],

			checkedPrpa: [],
			textarea1: '',
			pageData: {
				list: [],
			},
		};
	},
	methods: {
		selectAllOfSelectable(selection) {
			selectAllOfSelectable(selection, this.$refs.tableData, this.pageData2.list)
		},
		selectOfSelectable(selection) {
			selectOfSelectable(selection, this.$refs.tableData, this.pageData2.list)
		},
		initData() {
			this.handleSearchList2()
			this.getGoodsClassifyTree()
			if (this.dialogId && this.isEdit) {
				this.handleSearchList3()
			}
		},
		changeCheckList(e) {
			this.$forceUpdate();
		},
		closeDialogGoods() {
			this.dialogFormTableVisible = false
			this.listQuery = Object.assign({}, this.defaultListQuery)
			this.handleSearchList2();
		},
		//获取商品树类目
		getGoodsClassifyTree() {
			this.$store
				.dispatch('getGoodsClassifyTree', {})
				.then(res => {
					this.classifyTree = res
				});
		},
		handleCurrentChange(val) {
			this.listQuery.page = val;
			this.handleSearchList2();
		},
		handleSearchList3() {
			const data = {
				id: this.dialogId
			}
			this.$store
				.dispatch('getFxCoupon', data)
				.then(res => {
					if (res.couponGoodsIdList && res.couponGoodsIdList.length > 0) {
						this.handleSearchList4(res.couponGoodsIdList)
					}
					if (res.couponClassifyIdList && res.couponClassifyIdList.length > 0) {
						this.checkedKeys = res.couponClassifyIdList.slice(0)
					}

				});
		},
		handleSearchList4(goodId) {
			const data = [].concat(goodId)
			this.$store
				.dispatch('postPromotionId', data)
				.then(res => {
					this.pageDataSelectList = res
				});
		},
		handleSearchList2(listQuery) {
			let curSates = {
				page: this.listQuery.page,
				size: this.listQuery.size,
			}
			if (this.typeName == '促销') {
				this.argFrom2.promotion = false
				// this.listQuery.inPromotion="true"			
			}
			if (listQuery) {
				this.listQuery.page = 1
				let isOnShelf = ''
				let inPromotion = ''
				if (this.typeName === '促销') {
					inPromotion = "true"
				} else {
					if (listQuery.promotion === true) {
						inPromotion = "true"
					} else if (listQuery.promotion === false) {
						inPromotion = "false"
					}
				}

				if (listQuery.state === true) {
					isOnShelf = "true"
				} else if (listQuery.state === false) {
					isOnShelf = "false"
				}
				this.listQuery = {
					"countryCode": listQuery.country,
					"classifyId": '',
					"idFuzzy": listQuery.code,
					"brandCode": "",
					"updateUserId": "",//操作人
					"inPromotion": inPromotion, //是否促销
					"effectShelf": isOnShelf,//是否上架
					"beginCreateTime": "",
					"endCreateTime": '',
					"beginUpdateTime": "",
					"endUpdateTime": '',
					"page": this.listQuery.page,
					"size": this.listQuery.size,
				}
			}
			let temp = JSON.stringify(this.listQuery, (key, value) => {
				if (value !== null && value !== '') {
					return value;
				}
			});
			curSates = JSON.parse(temp);
			this.$store
				.dispatch('postGoodsSearch', curSates)
				.then(res => {
					this.pageData2 = res

					//回显
					if (this.$refs.tableData) {
						showSelectOfSelectable(this.$refs.tableData, this.pageData2.list)
					}
				});
		},
		handleResetSearch() {
			this.$emit('handleResetSearch')
		},
		handleSelectionChange(val) {
			this.pageDataSelectList = val;
			this.selectedMount = val.length
		},
		getActiveIndex(index) {
			this.activeIndex = index
		},
		changeTime(val) {
			if (val.length > 0) {
				this.formListQuery.startTime = new Date(val[0]).getTime()
				this.formListQuery.endTime = new Date(val[1]).getTime()
			}
		},
		changeGoods(row) {
			this.dialogFormTableVisible = true
			setTimeout(() => {
				if (this.$refs.tableData) {
					this.pageDataSelectList.forEach((item, index) => {
						this.$refs.tableData.toggleRowSelection(item, true);
					})
					//回显
					if (this.$refs.tableData) {
						showSelectOfSelectable(this.$refs.tableData, this.pageData2.list)
					}
					this.$forceUpdate()
				}
			}, 50)
		},
		changeStatus(val) {
			if (val == true) {
				this.formListQuery.status = "1"
			} else if (val == false) {
				this.formListQuery.status = "0"
			}
		},
		// renderContent(h, { node, data, store }) {
		//   return (
		//     <span class="custom-tree-node">
		//       <span>{node.label}</span>
		//       <span>
		//         <el-button size="mini" type="text" on-click={ () => this.append(data) }>Append</el-button>
		//         <el-button size="mini" type="text" on-click={ () => this.remove(node, data) }>Delete</el-button>
		//       </span>
		//     </span>
		// 	);
		// },
		salaryChange(e) {
			this.formListQuery.discount = e.target.value
		},
		//关闭窗口
		closeDialog() {
			this.pageDataSelectList = []
			this.$emit("closeDialog")
		},
		//选择商品
		handleSelected() {
			this.dialogFormTableVisible = false
		},
		handleSearchList() {
			this.$emit("handleSearchList")
		},
		quantityChange() {
			if (this.isEdit) {
				if (this.formListQuery.quantity < this.formListQueryQuantity) {
					this.formListQuery.quantity = this.formListQueryQuantity
					this.$message({
						message: this.$i18n.t("common.Thenumberofexchangecodescanonlybegreaterthantheoriginal"),
						type: 'warning',
						duration: 2000
					});
				}
			}
		},
		//提交
		onSubmit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$confirm( this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
						// confirmButtonText: '确定',
						// cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {

						console.log('this.formListQuery.type', this.formListQuery.type)
						//处理适用用户类型复选框
						if (this.formListQuery.userType.includes('NEW') && this.formListQuery.userType.includes('OLD')) {
							this.formListQuery.userType = 'ALL'
						} else if (this.formListQuery.userType.includes('NEW')) {
							this.formListQuery.userType = 'NEW'
						} else {
							this.formListQuery.userType = 'OLD'
						}

						if (this.formListQuery.type == 2 && this.formListQuery.discount1) {
							this.formListQuery.discount = this.formListQuery.discount1
						} else if (this.formListQuery.type == 1) {
							this.formListQuery.discount = this.formListQuery.discount / 100
						}
						delete this.formListQuery.discount1
						if (this.formListQuery.isFixedCode == 0) {
							this.formListQuery.code = randomString(12)
						}
						delete this.formListQuery.time
						this.formLoading = true;
						if (this.isEdit) {
							this.formListQuery.couponId = ''
							this.formListQuery.couponId = this.dialogId
							let keys = []
							this.pageDataSelectList.forEach((item, index) => {
								if (item.goodsId) {
									keys.push(item.goodsId)
								}
							})
							this.formListQuery.couponGoodsIdList = keys
							this.formListQuery.couponGoodsClassifiesIdList = this.$refs.tree.getCheckedKeys()
							this.$store
								.dispatch("updateCoupon", this.formListQuery).then(res => {
									this.$message({
										message:  this.$i18n.t("common.Successfullysubmitted"),
										type: 'success',
										duration: 1000
									});
									this.closeDialog()
									this.handleSearchList()
									this.formLoading = false
								}).catch((err) => {
									if (this.formListQuery.type == 1) {
										this.formListQuery.discount = this.formListQuery.discount * 100
									}
									this.formLoading = false
								});
						} else {
							let keys = []
							this.pageDataSelectList.forEach((item, index) => {
								if (item.goodsId) {
									keys.push(item.goodsId)
								}
							})
							this.formListQuery.couponGoodsIdList = keys
							this.formListQuery.couponGoodsClassifiesIdList = this.$refs.tree.getCheckedKeys()
							this.$store
								.dispatch("addCoupon", this.formListQuery).then(res => {
									this.$message({
										message:  this.$i18n.t("common.Successfullysubmitted"),
										type: 'success',
										duration: 1000
									});
									this.closeDialog()
									this.handleSearchList()
									this.formLoading = false
								}).catch((err) => {
									if (this.formListQuery.type == 1) {
										this.formListQuery.discount = this.formListQuery.discount * 100
									}
									this.formLoading = false
								});
						}
					});

				} else {
					this.$message({
						message:  this.$i18n.t("common.Verificationfailed"),
						type: 'error',
						duration: 1000
					});
					return false;
				}
			});
		},
		isShowGoodsPromotion,
		//获取促销价格
		getPromotionGoodsPrice,
	},
	mounted() {
		if (this.isEdit) {
			this.formListQueryQuantity = JSON.parse(JSON.stringify(this.formListQuery.quantity))
		}
		this.initData()
	}
};
</script>
<style scoped>
.input-width {
	width: 70%;
}

.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;
}

.dialog-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}

.prpa {
	display: flex;
	justify-content: center;
	align-items: center;
}

.prpa-sub {
	border: 1px solid #000000;
	width: 80%;
}

.content1 {
	width: auto;
	height: 100%;
	overflow-y: scroll;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.content-sub-left {
	cursor: pointer;
	position: relative;
	background-color: #ececec;
	width: 40%;
}

.content-sub-right {
	width: 60%;
}

.content-sub-item {
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	padding: 10px 0px 10px 10px;
}

.active::before {
	content: '';
	position: absolute;
	border-left: 4px solid #55aaff;
	height: 30px;
	left: 0px;
}

.active {
	font-weight: 900;
	font-style: normal;
	font-size: 20px;
	background-color: #46a8a0;
	color: white;
}

.content2 {
	width: auto;
	height: 100%;
}

.content2-sub {
	font-size: 15px;
	color: #464646;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
}</style>